<template>
  <Skills />
</template>

<script>
import Skills from '../Skills';

export default {
  name: 'CardSkills',
  components: {
    Skills,
  },
};
</script>

<style></style>
