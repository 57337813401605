<template>
  <div>
    <Bio />
  </div>
</template>

<script>
import Bio from '../Bio';

export default {
  name: 'CardBio',
  components: { Bio },
};
</script>

<style></style>
